import { createSharedComposable } from '@vueuse/shared';
import { useApi } from '../../../../services/api';
import type {
  InventoryKpis,
  LocationKpis,
  SkuKpis,
  Inventory,
  Location,
  Sku,
  LocationDetails,
  InventoryDetails,
  AvoidReplenishmentSettingUploadingTask,
  AvoidReplenishmentStatus,
} from '../../interfaces';
import type {
  MissingItem,
  PaginatedResponse,
  PaginationQuery,
  TransportRequestOptions,
} from '../../../../services/api';

export const useInventoryApi = createSharedComposable(() => {
  const { transport } = useApi();

  // Inventories

  function getInventoryKpis() {
    return transport.get<InventoryKpis>('/v1/inventories/kpis');
  }

  function getInventories(query?: PaginationQuery) {
    return transport.get<PaginatedResponse<Inventory>>('/v1/inventories', {
      query,
    });
  }

  function getInventoriesByIds(ids: string[]) {
    return transport.post<{ data: Array<Inventory | MissingItem> }>('/v1/inventories/by_ids', {
      ids,
    });
  }

  function getInventory(id: string) {
    return transport.get<InventoryDetails>(`/v1/inventories/${id}`);
  }

  // Locations

  function getLocationKpis() {
    return transport.get<LocationKpis>('/v1/locations/kpis');
  }

  function getLocations(query?: PaginationQuery) {
    return transport.get<PaginatedResponse<Location>>('/v1/locations', {
      query,
    });
  }

  function getLocationsByIds(ids: string[]) {
    return transport.post<{ data: Array<Location | MissingItem> }>('/v1/locations/by_ids', {
      ids,
    });
  }

  function getLocation(id: string) {
    return transport.get<LocationDetails>(`/v1/locations/${id}`);
  }

  // SKUs

  function getSkuKpis() {
    return transport.get<SkuKpis>('/v1/skus/kpis');
  }

  function getSkus(query?: PaginationQuery) {
    return transport.get<PaginatedResponse<Sku>>('/v1/skus', {
      query,
    });
  }

  function getSkuByIds(ids: string[]) {
    return transport.post<{ data: Array<Sku | MissingItem> }>('v1/skus/by_ids', {
      ids,
    });
  }

  function uploadAvoidReplenishmentSettings(
    file: File,
    scope: 'skus' | 'inventories' | 'locations',
    onUploadProgress?: TransportRequestOptions['onUploadProgress'],
  ) {
    return transport.post<{
      task_id: string;
    }>(
      '/v1/settings/avoid_replenishments/upload',
      { file, scope },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
      },
    );
  }

  function getAvoidReplenishmentStatus() {
    // This endpoint supports `scope` query param, but it's useless and just brings issues with types
    return transport.get<AvoidReplenishmentStatus>('/v1/settings/avoid_replenishments/status');
  }

  async function getLastAvoidReplenishmentSettingsUploadTask(
    scope: 'skus' | 'locations' | 'inventories',
  ) {
    const response = await transport.get<PaginatedResponse<AvoidReplenishmentSettingUploadingTask>>(
      '/v1/tasks/',
      {
        query: {
          scope: 'all',
          type: 'ArSettingsTask',
          by_user: false,
          page: 1,
          per_page: 100,
        },
      },
    );

    // TODO: remove when the BE orders tasks by creation date and adds query filter by metadata.scope
    const task = [...response.data.data].find((item) => item.metadata.scope === scope);
    response.data.data = task ? [task] : [];

    return response;
  }

  function cancelAvoidReplenishmentSettingsUploadTask(id: string) {
    return transport.put(`v1/tasks/abort/${id}`, {});
  }

  return {
    getInventory,
    getInventoryKpis,
    getInventories,
    getInventoriesByIds,
    getLocationKpis,
    getLocations,
    getLocationsByIds,
    getSkuKpis,
    getSkus,
    getSkuByIds,
    getLocation,
    uploadAvoidReplenishmentSettings,
    getLastAvoidReplenishmentSettingsUploadTask,
    cancelAvoidReplenishmentSettingsUploadTask,
    getAvoidReplenishmentStatus,
  };
});
