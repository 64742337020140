export const datetimeFormats = {
  datetime: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  date: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
  day: {
    month: 'short',
    day: 'numeric',
  },
  weekDay: {
    weekday: 'long',
  },
  month: {
    year: 'numeric',
    month: 'short',
  },
};

export const numberFormats = {
  pretty: {
    style: 'decimal',
    maximumFractionDigits: 2,
  },
  percent: {
    style: 'percent',
    maximumFractionDigits: 2,
  },
  compact: {
    notation: 'compact',
    compactDisplay: 'short',
    maximumFractionDigits: 1,
  },
  money: {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
};
