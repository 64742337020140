import type { Plugin } from 'vue';
import { log } from '../../shared/utils';

type HeapCallback = {
  name: string;
  fn: () => void;
};

type HeapMethod = (...args: any[]) => void;

type HeapInternal = {
  track: (event: string, properties?: Record<string, string | number>) => void;
  identify: (identity: string) => void;
  resetIdentity: () => void;
  addUserProperties: (properties: Record<string, string | number>) => void;
  addEventProperties: (properties: Record<string, string | number>) => void;
  removeEventProperty: (property: string) => void;
  clearEventProperties: () => void;
  appid: string;
  userId: string;
  identity: string | null;
  config: any;
  envId?: string;
  clientConfig?: { [key: string]: any };
  load: (envId: string, clientConfig?: { [key: string]: any }) => void;
} & {
  [key: string]: HeapMethod | undefined;
};

type Heap = Pick<
  HeapInternal,
  | 'track'
  | 'identify'
  | 'resetIdentity'
  | 'addUserProperties'
  | 'addEventProperties'
  | 'removeEventProperty'
  | 'clearEventProperties'
>;

declare global {
  interface Window {
    heapReadyCb: HeapCallback[];
    heap: HeapInternal;
  }
}

export function createHeap(envId: string): Plugin {
  return {
    install() {
      // Actually it's unminified and typescriptified code from https://developers.heap.io/docs/web
      // It looks like there is a mess with appId, appid and envId.
      window.heapReadyCb = window.heapReadyCb || [];
      window.heap = window.heap || {};

      window.heap.load = function (envId: string, clientConfig?: { [key: string]: any }) {
        window.heap.envId = envId;
        window.heap.clientConfig = clientConfig || {};
        window.heap.clientConfig.shouldFetchServerConfig = false;

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = `https://cdn.us.heap-api.com/config/${envId}/heap_config.js`;

        const firstScript = document.getElementsByTagName('script')[0];
        firstScript.parentNode?.insertBefore(script, firstScript);

        const methods: string[] = [
          'init',
          'startTracking',
          'stopTracking',
          'track',
          'resetIdentity',
          'identify',
          'identifyHashed',
          'getSessionId',
          'getUserId',
          'getIdentity',
          'addUserProperties',
          'addEventProperties',
          'removeEventProperty',
          'clearEventProperties',
          'addAccountProperties',
          'addAdapter',
          'addTransformer',
          'addTransformerFn',
          'onReady',
          'addPageviewProperties',
          'removePageviewProperty',
          'clearPageviewProperties',
          'trackPageview',
        ];

        // Function to queue API calls until Heap is ready
        const createApiMethod = (methodName: string): HeapMethod => {
          return (...args: any[]) => {
            window.heapReadyCb.push({
              name: methodName,
              fn: () => {
                const method = window.heap[methodName];
                if (method) {
                  method.apply(window.heap, args);
                }
              },
            });
          };
        };

        // Assign each API method to the `heap` object
        for (const methodName of methods) {
          window.heap[methodName] = createApiMethod(methodName);
        }
      };

      window.heap.load(envId);
    },
  };
}

export function useHeap(): Heap {
  return {
    track(event: string, properties?: Record<string, string | number>) {
      if (!window.heap) {
        log(
          `Track heap event "${event}"` +
            (properties ? ` with properties "${JSON.stringify(properties)}"` : ''),
        );
        return;
      }

      window.heap.track(event, properties);
    },
    identify(identity: string) {
      if (!window.heap) {
        return;
      }

      window.heap.identify(identity);
    },
    resetIdentity() {
      if (!window.heap) {
        return;
      }

      window.heap.resetIdentity();
    },
    addUserProperties(properties: Record<string, string | number>) {
      if (!window.heap) {
        return;
      }

      window.heap.addUserProperties(properties);
    },
    addEventProperties(properties: Record<string, string | number>) {
      if (!window.heap) {
        return;
      }

      window.heap.addEventProperties(properties);
    },
    removeEventProperty(property: string) {
      if (!window.heap) {
        return;
      }

      window.heap.removeEventProperty(property);
    },
    clearEventProperties() {
      if (!window.heap) {
        return;
      }

      window.heap.clearEventProperties();
    },
  };
}
